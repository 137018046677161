import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import HomeImage from "../images/react-dev-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import ItStaffComponent from "../component/Services/IT/ItStaffComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";
import { graphql } from "gatsby";
import BorderCards from "../component/BorderCards";
const ServiceReactDev = (props) => {
  const seo = {
    title: "Outsource React Development Company in USA | Motomtech",
    metaDesc:
      "Outsource your React development to Motomtech’s React experts to get more out of your website, software, and more",
  };
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: HomeImage },
            },
          },
        },
        aboutUsHomeSection: {
          aboutUsHomeTitle,
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTextThree,
        },
        textField: {
          titleFieldTitle,
          textFieldSubtitle,
          textFieldDescription,
          textFieldCardTitle1,
          textFieldCardDescription1,
          textFieldCardTitle2,
          textFieldCardDescription2,
          textFieldCardTitle3,
          textFieldCardDescription3,
          textFieldCardTitle4,
          textFieldCardDescription4,
        },
        blogMvpSection: {
          blogMvpButton,
          blogMvpData,
          blogMvpDescription,
          blogMvpName,
          blogMvpText,
          blogMvpTitle,
        },
        digitalApplicationDevelopmentServices: {
          digitalApplicationDevelopmentFrontend,
          digitalApplicationDevelopmentBackend,
          digitalApplicationDevelopmentProgressive,
          digitalApplicationDevelopmentSaas,
          digitalApplicationDevelopmentECommerce,
          digitalApplicationDevelopmentCustom,
          digitalApplicationDevelopmentTitle,
          digitalApplicationDevelopmentDescription,
        },
        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
        },
      },
    },
  } = props;

  const arr1 = [
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Custom ReactJS
          <span> Plugins:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentProgressive,
      number: "1",
    },

    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          React Integration
          <span> Services:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentSaas,
      number: "2",
    },

    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Social Networking
          <span> Development:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentECommerce,
      number: "3",
    },
  ];
  const arr2 = [
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          UI/UX
          <span> Development:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentCustom,
      number: "4",
    },
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          ReactJS
          <span> Migration:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentTitle,
      number: "5",
    },
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          SPA
          <span> Development:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentDescription,
      number: "6",
    },
  ];

  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={HomeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={aboutUsHomeTitle}
        description1={aboutUsHomeDescription}
        description2={aboutUsHomeSolutionTitle}
        description3={aboutUsHomeSolutionTextOne}
        description4={aboutUsHomeSolutionTextTwo}
        description5={aboutUsHomeSolutionTextThree}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <p
          className="section-container-title"
          dangerouslySetInnerHTML={{ __html: titleFieldTitle }}
        />
        <BorderCards
          borderCardsText1={textFieldSubtitle}
          borderCardsText2={textFieldDescription}
          borderCardsText3={textFieldCardTitle1}
          borderCardsText4={textFieldCardDescription1}
          borderCardsText5={textFieldCardTitle2}
          borderCardsText6={textFieldCardDescription2}
          borderCardsText7={textFieldCardTitle3}
          borderCardsText8={textFieldCardDescription3}
          borderCardsText9={textFieldCardTitle4}
          borderCardsText10={textFieldCardDescription4}
        />
      </div>
      <ItExpandTeamComponent
        title={blogMvpTitle}
        description1={blogMvpName}
        description2={blogMvpText}
        description3={blogMvpData}
        description4={blogMvpDescription}
        description5={blogMvpButton}
      />
      <ItStaffComponent
        title={digitalApplicationDevelopmentFrontend}
        description={digitalApplicationDevelopmentBackend}
        arr1={arr1}
        arr2={arr2}
      />
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={processSubtitle}
        subtitle={processDescription}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceReactDev;
export const query = graphql`
  {
    wpPage(slug: { eq: "service-react-development" }) {
      seo {
        title
        metaDesc
      }
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      aboutUsHomeSection {
        aboutUsHomeTitle
        aboutUsHomeDescription
        aboutUsHomeSolutionTitle
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTextThree
      }
      textField {
        titleFieldTitle
        textFieldSubtitle
        textFieldDescription
        textFieldCardTitle1
        textFieldCardDescription1
        textFieldCardTitle2
        textFieldCardDescription2
        textFieldCardTitle3
        textFieldCardDescription3
        textFieldCardTitle4
        textFieldCardDescription4
      }
      blogMvpSection {
        blogMvpButton
        blogMvpData
        blogMvpDescription
        blogMvpName
        blogMvpText
        blogMvpTitle
      }
      digitalApplicationDevelopmentServices {
        digitalApplicationDevelopmentFrontend
        digitalApplicationDevelopmentBackend
        digitalApplicationDevelopmentProgressive
        digitalApplicationDevelopmentSaas
        digitalApplicationDevelopmentECommerce
        digitalApplicationDevelopmentCustom
        digitalApplicationDevelopmentTitle
        digitalApplicationDevelopmentDescription
      }

      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
      }
    }
  }
`;
